import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
    border: 2px solid ${({theme}) => theme.color.blue};
    background-color: ${({theme}) => theme.color.blue};
    color: ${({theme}) => theme.color.white};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family.avenir};
    font-weight: bold;
    letter-spacing: 1.6px;
    padding: 13px 22px;

    ${({ theme }) => theme.mq.desktop} {
        padding: 18px 25px;
    }

    ${({ theme }) => theme.mq.widescreen} {
        padding: 18px 35px;
    }
`

const Button = ({ className, children, ...buttonProps}) => (
    <StyledButton className={className} {...buttonProps}>{children}</StyledButton>
)

export default Button