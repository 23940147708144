import React from "react"
import styled from "styled-components"
import { Formik, Field, Form } from "formik"
import emailjs from 'emailjs-com'
import Button from "../../atoms/Button/Button"
import checkedIcon from "../../../images/checked.svg"
import{ init } from 'emailjs-com';
init("user_1D6pYY8YjqZc20l3BMyhk");

const StyledForm = styled(Form)`
    width: 100%;
`

const StyledField = styled(Field)`
    width: 100%;
    background: none;
    border: none;
    border-bottom: 2px solid #EFEFEF;
    padding: 16px 0;
    margin: 8px 0;
    font-family: ${({theme}) => theme.font.family.avenir};
    font-size: 1.6rem;
`

const StyledButton = styled(Button)`
    margin-top: 1.6rem;
`

const StyledMessage = styled.div`
    width: 100%;
    padding: 10px 10px;
    background-color: #4caf50;
    color: white;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
`

const Icon = styled.img`
    height: 20px;
    margin-right: 12px;
`

const ContactForm = ({ className }) => (
    <Formik initialValues={{ name: "", email: "", message: ""}}
    validate={values => {
        const errors = {}
        if(!values.email) {
            errors.email = "Required"
        } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address"
        }
        return errors
    }}
    onSubmit={(values, { setSubmitting, resetForm, setStatus, status}) => {
        emailjs.send("service_gqfqa2s","template_qkpzzpi",{
            name: values.name,
            email: values.email,
            message: values.message,
            }).then((result) => {
                setStatus({ message: "Wiadomość została wysłana" })
                setTimeout(() => {
                    resetForm();
                }, 4000);
            }, (error) => {
            });
        
    }}
    >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status }) => (
            <StyledForm className={className} onSubmit={handleSubmit}>
                <StyledField id="name" type="text" name="name" placeholder="Imię i nazwisko" onChange={handleChange} onBlur={handleBlur} value={values.name}/>
                <StyledField type="e-mail" name="email" placeholder="E-mail" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
                <StyledField component="textarea" rows={5} name="message" placeholder="Tu wpisz treść wiadomości" onChange={handleChange} onBlur={handleBlur} value={values.message}/>
                {status && status.message && (
                    <StyledMessage><Icon src={checkedIcon} />{status.message}</StyledMessage>
                )}
                <StyledButton type="submit" disabled={isSubmitting}>
                Wyslij wiadomość
                </StyledButton>
                
            </StyledForm>
        )}
    </Formik>
)

export default ContactForm