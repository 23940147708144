import React, { useState } from "react"
import styled from "styled-components"

import plusIcon from "../../../images/plus-icon.svg"
import Tooltip from "../../atoms/Tooltip/Tooltip"

const SliderHighlightContainer = styled.div`
    position: absolute;
    top: ${props => props.positionY};
    left: ${props => props.positionX};
    display: flex;
    flex-direction: column;
    align-items: center;
`

const HighlightButton = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.blue};

    transition: all 0.4s ease;

    &::before {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.blue};
        opacity: 0.6;
        z-index: -1;
        transform: ${({ isShown }) => isShown ? "scale(1.5)" : "scale(1)"};
        transition: all 0.2s linear;
    }
`

const StyledIcon = styled.img`
    width: 20px;
    transition: all 0.2s linear;

    transform: ${({ isShown }) => isShown ? "rotate(45deg)" : "rotate(0deg)"};
`

const SliderHighlight = ({children, positionX, positionY}) => {
    const [isShown, setIsShown] = useState(false)

    return (
    <SliderHighlightContainer positionX={positionX} positionY={positionY}>
        <Tooltip isShown={isShown}>{children}</Tooltip>
        <HighlightButton onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} isShown={isShown}><StyledIcon isShown={isShown} src={plusIcon}/></HighlightButton>
    </SliderHighlightContainer>
    )
}

export default SliderHighlight