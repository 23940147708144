import React, { useState, useRef } from "react"
import styled from "styled-components"
import calendarIcon from "../../../images/calendar.svg"
import Paragraph from "../../atoms/Paragraph/Paragraph"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: hidden;
`

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;

    ${({ theme }) => theme.mq.desktop} {
        max-height: 300px;
    }
`

const StyledVideo = styled.video`
    width: 100%;
`

const StyledImage = styled.img`
    width: 100%;
`

const TextContainer = styled.div`
    width: 100%;
    padding: 20px;
`

const ParagraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ReadMoreButton = styled.button`
    border: 0;
    background: none;
    color: ${({ theme }) => theme.color.blue};
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.font.family.avenir};
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
`

const StyledDateContainer = styled.div`
    color: #414141;
    opacity: 0.7;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
`

const StyledIcon = styled.img`
    margin-right: 1rem;
`

const StyledDate = styled.time`

`

const NewsCard = ({className, text, date, attachment}) => {
    const [state, setState] = useState(false)
    const slicedTextRef = useRef(null)

    const sliceText = (param) => {
        let slicedText = param.slice(0, 200)
        let newText = `${slicedText}...`

        return newText
    }

    return (
<StyledWrapper className={className}>
            <ImageContainer>
                {attachment.media_type === "video" ?  
                <StyledVideo controls>
                    <source src={attachment.media.source} type="video/mp4"/>
                </StyledVideo> :
                <StyledImage src={attachment.media.image.src}/>} 
            </ImageContainer>
            <TextContainer>
            <StyledDateContainer>
                    <StyledIcon src={calendarIcon}/>
                    <StyledDate>{date}</StyledDate>
                </StyledDateContainer>
                <ParagraphContainer>
                <Paragraph style={{ display: state === true ? "none" : "block"}} ref={slicedTextRef}>{sliceText(text)}</Paragraph>
                {state && <Paragraph>{text}</Paragraph>}
                <ReadMoreButton onClick={(e) => setState(!state)}>Czytaj więcej</ReadMoreButton>
                </ParagraphContainer>
            </TextContainer>
        </StyledWrapper>
    )
}

export default NewsCard