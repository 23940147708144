import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SliderHighlight from "../../molecules/SliderHighlight/SliderHighlight"

import arrowLeft from "../../../images/arrow-left.svg"
import arrowRight from "../../../images/arrow-right.svg"

import { StyledSection, StyledImg, SliderButtons, SliderControl, SliderItems, Slide, Arrow } from "./Slider.styles"

const Slider = () => {
    const data = useStaticQuery(query)
    const listRef = useRef(null)
    const [value, setValue] = useState(0)

    const handlePrevSlide = () => {
        listRef.current.style.transform = value === 0 ? `translateX(0%)` : `translateX(-${value - 1}00%)`
        if (value > 0) {
            setValue(value - 1)
        } 
    }

    const handleNextSlide = () => {
        if (value < 2) {
            listRef.current.style.transform = `translateX(-${value + 1}00%)`
            setValue(value + 1)
        }
    }

    const slides = {
        slide1: {
            image: data.slide1.childImageSharp.fluid,
            highlights: [
                {id: 0, positionX: "52%", positionY: "11%", text: "Taras widokowy, kawiarnia"},
            {id: 1, positionX: "55%", positionY: "42%", text: "Sale konferencyjne"},
            {id: 2, positionX: "22%", positionY: "23%", text: "Szklane balkony"},
            {id: 3, positionX: "77%", positionY: "25%", text: "Eksluzywne pokoje"},
            {id: 4, positionX: "7%", positionY: "49%", text: "Villa Collis SPA & Basen"},
            {id: 5, positionX: "38%", positionY: "30%", text: "Recepcja, wejście główne"},
            {id: 6, positionX: "35%", positionY: "63%", text: "Ogród, spacerniak"}
            ]
        },
        slide2: {
            image: data.slide2.childImageSharp.fluid,
            highlights: [
                {id: 0, positionX: "28%", positionY: "43%", text: "Parking"},
                {id: 1, positionX: "42%", positionY: "32%", text: "Hotel"},
                {id: 2, positionX: "48%", positionY: "0%", text: "Szklane balkony"},
                {id: 3, positionX: "74%", positionY: "0%", text: "Altana grillowa"},
                {id: 4, positionX: "77%", positionY: "-12%", text: "Lądowisko"},
                {id: 5, positionX: "62%", positionY: "42%", text: "Villa Collis SPA & Basen"},
                {id: 6, positionX: "70%", positionY: "70%", text: "Park"},
            ]
        },
        slide3: {
            image: data.slide3.childImageSharp.fluid,
            highlights: [
                {id: 0, positionX: "10%", positionY: "30%", text: "Zalew Soliński"},
                {id: 1, positionX: "72%", positionY: "23%", text: "Przystań wodna"},
                {id: 2, positionX: "43%", positionY: "60%", text: "Hotel"},
            ]
        }
    }

    return (
        <StyledSection>
            <SliderItems ref={listRef}>
                    {Object.keys(slides).map((slide, index) => (
                        <Slide key={index} value={value} index={index}><StyledImg fluid={slides[slide].image} />
                            {slides[slide].highlights && slides[slide].highlights.map((pin, index) => (
                                <SliderHighlight key={index} positionX={pin.positionX} positionY={pin.positionY}>{pin.text}</SliderHighlight>
                            ))}
                        </Slide>
                    ))}
            </SliderItems>
            <SliderButtons>
                    <SliderControl onClick={handlePrevSlide}><Arrow src={arrowLeft} /></SliderControl>
                    <SliderControl onClick={handleNextSlide}><Arrow src={arrowRight} /></SliderControl>
            </SliderButtons>            
        </StyledSection>
    )
}

export const query = graphql`
    {
        slide1: file(name: {eq: "poznajnaszainwestycje-min"}) {
            childImageSharp {
                fluid (maxWidth: 1920, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }  
        slide2: file(name: {eq: "discover-investment-2"}) {
            childImageSharp {
                fluid (maxWidth: 1920, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 
        slide3: file(name: {eq: "discover-investment-3"}) {
            childImageSharp {
                fluid (maxWidth: 1920, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }    
    }
`

export default Slider