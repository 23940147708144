/*global FB*/
import React, { useEffect, useState } from "react"
import TextStack from "../../molecules/TextStack/TextStack"
import { Section } from "../../atoms/Section/Section"
import { Wrapper } from "../../atoms/Wrapper/Wrapper"

import { NewsContainer, StyledLink, StyledNewsCard } from "./NewsSection.styles"

const formattedDate = (date) => {
    var strDate = date.substring(0,10);
    return strDate
}

const News = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        if (typeof(FB) === "undefined") { return }
        FB.api(
            `/Hotel.Collis.Investment/feed?access_token=${process.env.GATSBY_FB_ACCESS_TOKEN}`,
            'GET',
            {"fields": "attachments{media_type, media}, created_time, message, permalink_url", "limit":"3"},
            function(response) {
                setData(response.data)
            }
          );
    }, [])

    if (!data) {
        return null
    }

    return (
    <Section>
        <Wrapper>
            <TextStack span="Aktualnosci" title="Najnowsze aktualności prosto z Bieszczad" />
            <NewsContainer>
                {data.map((item, index) => (
                    <StyledLink href={item.permalink_url} target="_blank"><StyledNewsCard key={item.id} attachment={item.attachments.data[0]} text={item.message} date={formattedDate(item.created_time)}  /></StyledLink>
                ))}
            </NewsContainer>
        </Wrapper>
    </Section>
    )
}

export default News