import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TextStack from "../../molecules/TextStack/TextStack"
import { Section } from "../../atoms/Section/Section"

import { StyledWrapper, InfoContainer, ButtonLink, StyledImg, StyledImgSecond } from "./LocationSection.styles"

const Location = ({title, text}) => {
    const data = useStaticQuery(query)

    return (
        <Section>
            <StyledWrapper>
                <InfoContainer>
                    <TextStack span="lokalizacja" title={title} paragraph={text} />
                    <ButtonLink cover
                        to="/lokalizacja"
                        direction="left"
                        duration={1}
                        color="#212548">
                        Czytaj Dalej
                    </ButtonLink>
                    </InfoContainer>
                    <StyledImg fluid={data.location1.childImageSharp.fluid} />
                    <StyledImgSecond fluid={data.location2.childImageSharp.fluid} />
                </StyledWrapper>
            </Section>
    )
}

export const query = graphql`
    {
        location1: file(name: {eq: "lokalizacja-1"}) {
            childImageSharp {
                fluid (maxWidth: 1000, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        location2: file(name: {eq: "lokalizacja-2"}) {
            childImageSharp {
                fluid (maxWidth: 1920, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`

export default Location