import React from "react"
import TextStack from "../../molecules/TextStack/TextStack"
import { Section } from "../../atoms/Section/Section"

import { StyledWrapper, InfoContainer, ButtonLink, StyledInvestmentsBox } from "./InvestmentSection.styles"

const InvestmentsSection = ({ title, text }) => (
    <Section>
        <StyledWrapper>
            <InfoContainer>
                <TextStack span="Inwestycje w Bieszczadach" title={title} paragraph={text} reversed/>
                <ButtonLink cover
                        to="/lokalizacja"
                        direction="left"
                        duration={1}
                        color="#212548">
                        Czytaj Dalej
                </ButtonLink>
            </InfoContainer>
        <StyledInvestmentsBox />
        </StyledWrapper>
    </Section>
)

export default InvestmentsSection