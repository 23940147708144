import styled from "styled-components"
import NewsCard from "../../molecules/NewsCard/NewsCard"

export const NewsContainer = styled.div`
    ${({ theme }) => theme.mq.desktop} {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5%;
    }
`

export const StyledNewsCard = styled(NewsCard)`
    margin-bottom: 4rem;
`

export const StyledLink = styled.a`
    text-decoration: none;
`