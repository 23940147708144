import React from "react"
import styled from "styled-components"
import { Span } from "../Span/Span"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledIcon = styled.img`

`

const StyledSpan = styled(Span)`
    margin-top: 2.4rem;
    color: #414141;
`

const InvestmentsBoxItem = ({className, icon, text}) => (
    <StyledWrapper className={className}>
        <StyledIcon src={icon} />
        <StyledSpan>{text}</StyledSpan>
    </StyledWrapper>
)

export default InvestmentsBoxItem