import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Gallery from "../../components/organisms/Gallery/Gallery"

const StyledGallery = styled(Gallery)`
  ${({ theme }) => theme.mq.desktop} {
    padding-top: 100px;
  }
`

const GalleryTemplate = () => {
  const data = useStaticQuery(query)
  return (
    <StyledGallery
      images={data.allFile.edges.map(({ node }) => node.childImageSharp)}
    />
  )
}

export const query = graphql`
  query ImagesForGallery {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 1000, maxHeight: 570) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default GalleryTemplate
