import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import arrowIcon from "../../../images/arrow-down.svg"
import TextStack from "../../molecules/TextStack/TextStack"
import NumbersBox from "../../molecules/NumbersBox/NumbersBox"
import { StyledSection, ScrollButton, StyledWrapper, InfoContainer, ButtonsContainer, ButtonLink, StyledImg, StyledIcon, StyledDownloadLink } from "./AboutInvestment.styles"

const AboutInvestment = ({title, text}) => {
    const data = useStaticQuery(query)
    const aboutSectionRef = useRef(null)

    const scrollToBottom = () => {
        aboutSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return (
        <StyledSection ref={aboutSectionRef}>
            <ScrollButton onClick={scrollToBottom}><StyledIcon src={arrowIcon} />Zescrolluj w dół</ScrollButton>
            <StyledWrapper>
            <InfoContainer>
                <TextStack span="o inwestycji" title={title} paragraph={text} />
                <ButtonsContainer>
                    <ButtonLink cover="true"
                        to="/o-inwestycji"
                        direction="left"
                        duration={1}
                        color="#212548">
                        Czytaj Dalej
                    </ButtonLink>
                    <StyledDownloadLink href={`../../../oferta.pdf`}>Materialy do pobrania</StyledDownloadLink>
                </ButtonsContainer>
                <NumbersBox />
            </InfoContainer>
            <StyledImg fluid={data.file.childImageSharp.fluid} />
            </StyledWrapper>
        </StyledSection>
    )
}

export const query = graphql`
    {
        file(name: {eq: "about-investment"}) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`

export default AboutInvestment