import React from "react"

import Hero from "../components/organisms/Hero/Hero"
import AboutInvestment from "../components/organisms/AboutInvestment/AboutInvestment"
import Slider from "../components/organisms/Slider1/Slider"
import Location from "../components/organisms/LocationSection/LocationSection"
import HomeTemplate from "../templates/HomeTemplate/HomeTemplate"
import GalleryTemplate from "../templates/GalleryTemplate/GalleryTemplate"
import InvestmentsSection from "../components/organisms/InvestmentSection/InvestmentSection"
import News from "../components/organisms/NewsSection/NewsSection"
import Contact from "../components/organisms/ContactSection/ContactSection"
import Footer from "../components/organisms/Footer/Footer"

const IndexPage = ({ data }) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <HomeTemplate>
      <Hero
        title="Zainwestuj w Bieszczadach!"
        claim="Zainwestuj w apartament w luksusowym hotelu i ciesz się stabilnym zyskiem!"
        images={sources}
      />
      <AboutInvestment
        title="Luksusowy resort górski Hotel Collis powstanie w otoczeniu bieszczadzkich szczytów, z dala od zgiełku typowego wakacyjnego kurortu."
        text="Projekt inwestycji został stworzony zgodnie z tradycją regionu, dzięki czemu w harmonijny sposób wkomponowuje się w malownicze krajobrazy wzgórz górujących nad Soliną. Malowniczo położony budynek będzie zawierał 118 komfortowych pokoi po ok. 35 m2, w tym 10 luksusowych, przestronnych apartamentów po ok. 60 m2. Obiekt będzie wyposażony we wszelkie udogodnienia w tym: sale konferencyjne, restaurację, widokowy bar na szczycie, strefę spa, wellness, fitness, siłownię i gabinety masażu oraz zaplecze kosmetyczne. Inwestycja została zaprojektowana z zastosowaniem nowoczesnych technologii i dbałością o każdy detal, dzięki czemu wyróżnia się wysublimowanym stylem, który doskonale wkomponowuje się w otaczającą naturę. Całoroczny, luksusowy i jednocześnie kameralny obiekt wypoczynkowy o najwyższym standardzie, posiadający wszelkie udogodnienia z całą pewnością podbije serca gości. "
      />
      <Slider />
      <Location
        title="Nasza inwestycja leży w górach, które kochamy."
        text="Doskonałe umiejscowienie Hotelu Collis pozwoli gościom, w pełni cieszyć się wszystkimi letnimi atrakcjami, jednocześnie zachowując poczucie spokoju i komfortu życia. Inwestycja jest prowadzona na malowniczej działce, o powierzchni 7 hektarów, na wzniesieniu półwyspu w miejscowości Olchowiec, w sercu Bieszczadów. Z jednej strony otoczona jest wodami jeziora Solina, z drugiej sąsiaduje z najdzikszym górskim pasmem Bieszczadów – Otrytem. Przepiękne widoki i bezpośredni dostęp do jeziora i lasów, dogodny dojazd, sąsiedztwo wielu szlaków pieszych i rowerowych, doliny rzeki San, spokój, cisza i piękna przyroda czynią to miejsce wyjątkowym. Dzięki dogodnej lokalizacji obiekt jest doskonałą bazą wypadową do wszelkich zakątków malowniczych Bieszczad i idealnym wyborem dla wszystkich poszukujących przestrzeni do wypoczynku. Malownicze położenie w sercu Bieszczad, z dostępem do jeziora i gór oraz możliwość zagospodarowania dużego terenu na liczne atrakcje sprawiają, że inwestycja nosi wszelkie znamiona powodzenia."
      />
      <GalleryTemplate />
      <InvestmentsSection
        title="Dynamiczny rozwój infrastruktury Bieszczad"
        text="Bieszczady są obecnie regionem, który z roku na rok zaczyna być coraz bardziej doceniany przez turystów ze względu na swój wyjątkowy urok, dzikość, ciszę i wiele atrakcji turystycznych. Na terenie Bieszczad rozwija się infrastruktura drogowa i noclegowa, powstają obiekty hotelowe. Widoczne jest spore zainteresowanie inwestycjami w nieruchomości na wynajem krótkoterminowy. Bieszczady posiadają wszelkie pożądane przez odwiedzających walory turystyczne: góry, lasy, jezioro, wiele szlaków turystycznych pieszych i rowerowych. Na terenie Bieszczad znajduje się wiele parków w tym narodowy i krajobrazowe oraz znajdują się tu liczne rezerwaty."
      />
      <News />
      <Contact />
      <Footer />
    </HomeTemplate>
  )
}

export const query = graphql`
  query {
    mobileImage: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(name: { eq: "hero-jasne" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
