import React from "react"
import TextStack from "../../molecules/TextStack/TextStack"
import { Section } from "../../atoms/Section/Section"

import { StyledWrapper, InnerWrapper, ContactInfoHeader, ContactInfoItem, ContactInfoWrapper, StyledParagraph, StyledContactForm } from "./ContactSection.styles"

const Contact = () => (
    <Section id="contact">
        <StyledWrapper id="contact">
            <InnerWrapper>
                <TextStack span="Kontakt" title="Skontaktuj się z nami" paragraph="Wszystkich zainteresowanych serdecznie zapraszamy do kontaktu!" />
                <ContactInfoWrapper>
                    <ContactInfoItem>
                        <ContactInfoHeader>Adres</ContactInfoHeader>
                        <StyledParagraph>Olchowiec 46 38-710 <br />Czarna, Polska</StyledParagraph>
                    </ContactInfoItem>
                    <ContactInfoItem>
                        <ContactInfoHeader>Kontakt</ContactInfoHeader>
                        <StyledParagraph>603 819 799 <br />k.kuncelman@collis.pl</StyledParagraph>
                    </ContactInfoItem>
                </ContactInfoWrapper>
            </InnerWrapper>
            <StyledContactForm />
        </StyledWrapper>
    </Section>
)

export default Contact