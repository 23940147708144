import styled from "styled-components"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import ContactForm from "../../organisms/ContactForm/ContactForm"
import { Wrapper } from "../../atoms/Wrapper/Wrapper"

export const StyledWrapper = styled(Wrapper)`
    ${({ theme }) => theme.mq.desktop} {
        display: flex;
        justify-content: space-between;
    }
`

export const InnerWrapper = styled.div`
    ${({ theme }) => theme.mq.desktop} {
        width: 45%;
    }
`

export const ContactInfoItem = styled.div`
    margin-bottom: 24px;

    ${({ theme }) => theme.mq.desktop} {
        margin-right: 6.4rem;
    }
`

export const ContactInfoWrapper = styled.div`
    ${({ theme }) => theme.mq.desktop} {
        display: flex;
    }
`

export const ContactInfoHeader = styled.h3`
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 1.6px;
    margin-bottom: .8rem;
`

export const StyledParagraph = styled(Paragraph)`
    line-height: normal;
`

export const StyledContactForm = styled(ContactForm)`
    ${({theme}) => theme.mq.desktop}{
        width: 40%;
    }
`