import styled from "styled-components"
import Img from "gatsby-image"
import { Section } from "../../atoms/Section/Section";

export const StyledSection = styled(Section)`
    display: none;

    ${({ theme }) => theme.mq.desktop} {
        display: block;
        width: 100vw;
        height: 100vh;
        position: relative;
        margin-top: 150px;
        padding-top: 0;
    }
`

export const SliderItems = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 100%);
    transition: ease-in-out 500ms;
`

export const Slide = styled.div`
    position: relative;
`

export const StyledImg = styled(Img)`
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover!important;
`

export const SliderButtons = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10000000;
`

export const SliderControl = styled.button`
    border: none;
    background: none;
    background: ${({ theme }) => theme.color.blue};
    padding: 20px 35px;

    ${({ theme }) => theme.mq.desktop } {
        
    }
`

export const Arrow = styled.img`
    height: 15px;

    ${({ theme }) => theme.mq.desktop} {
        height: 20px;   
    }
`