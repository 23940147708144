import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import { Grid, ImgWrapper, StyledImg, GalleryItem } from "./Gallery.styles"

const Gallery = ({
  images = null,
  thumbs = null,
  fullImages = null,
  gutter = '0rem',
  imgClass = '',
  lightboxOptions = {},
  className,
}) => {
  let thumbsArray, fullArray, thumbAltArray
  if (thumbs === null && fullImages === null) {
    thumbsArray = images.map(({ thumb }) => thumb)
    fullArray = images.map(({ full }) => full.src)
    thumbAltArray = images.map(({ thumbAlt }) => thumbAlt)
  }

  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const prevIndex = index - (1 % fullArray.length)
  const nextIndex = (index + fullArray.length + 1) % fullArray.length

  return (
    <div className={className}>
      <Grid>
        {thumbsArray.map((thumbnail, thumbIndex) => {
          return (
            <GalleryItem key={thumbIndex} item={thumbIndex} onClick={() => {
              setIsOpen(true)
              setIndex(thumbIndex)
            }}>
                       <ImgWrapper margin={gutter}>
                <StyledImg
                  fluid={thumbnail}
                  className={imgClass}
                  alt={
                    thumbAltArray
                      ? thumbAltArray[thumbIndex]
                        ? thumbAltArray[thumbIndex]
                        : ''
                      : ''
                  }
                />
              </ImgWrapper>
            </GalleryItem>
          )
        })}
      </Grid>
      {isOpen && (
        <Lightbox
          mainSrc={fullArray[index]}
          nextSrc={fullArray[nextIndex]}
          prevSrc={fullArray[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          imageTitle={images[index].title}
          imageCaption={images[index].caption}
          {...lightboxOptions}
        />
      )}
    </div>
  )
}

export default Gallery

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      full: PropTypes.object,
      thumb: PropTypes.object,
      thumbAlt: PropTypes.string,
      title: PropTypes.node,
      caption: PropTypes.node,
    })
  ),
  thumbs: PropTypes.array,
  fullImages: PropTypes.array,
  colWidth: PropTypes.number,
  mdColWidth: PropTypes.number,
  gutter: PropTypes.string,
  imgClass: PropTypes.string,
  lightboxOptions: PropTypes.object,
}