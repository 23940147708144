import React from "react"
import styled from "styled-components"

import Paragraph from "../Paragraph/Paragraph"
import pattern from "../../../images/pattern-brown.svg"

const StyledTooltip = styled.div`
    width: 320px;
    background-color: ${({ theme }) => theme.color.blue};
    background-image: url(${pattern});
    background-size: 100%;
    background-position: 0px 65px;
    background-repeat: no-repeat;
    border-radius: 3px;
    padding: 30px 20px 60px 20px;
    transition: all 250ms ease-in-out;
    box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    z-index: 100;

    transform: ${({ isShown }) => isShown ? "translateY(-30px)" : "translateY(-50px)"};
    opacity: ${({ isShown }) => isShown ? "1" : "0"};
    visibility: ${({ isShown }) => isShown ? "initial" : "hidden"};

    &::after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: ${({ theme }) => theme.color.blue};
        border-width: 7px;
        margin-left: -7px;
    }
`

const StyledParagraph = styled(Paragraph)`
    text-align: center;
    color: ${({ theme }) => theme.color.white};
    line-height: 1.4;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.9px;
    font-size: 1.4rem;
`

const Tooltip = ({ children, isShown }) => (
    <StyledTooltip isShown={isShown}>
        <StyledParagraph>{children}</StyledParagraph>
    </StyledTooltip>
)

export default Tooltip