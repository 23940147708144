import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import InvestmentsBox from "../../molecules/InvestmentsBox/InvestmentsBox"
import { Wrapper } from "../../atoms/Wrapper/Wrapper"

export const StyledWrapper = styled(Wrapper)`
    ${({ theme }) => theme.mq.desktop} {
        display: flex;
        justify-content: space-between;
    }
`

export const InfoContainer = styled.div`
    text-align: right;
    
    ${({ theme }) => theme.mq.desktop} {
        width: 50%;
        order: 2;
    }

    ${({theme }) => theme.mq.widescreen} {
        width: 45%;
    }
`

export const StyledInvestmentsBox = styled(InvestmentsBox)`
    ${({ theme }) => theme.mq.desktop} {
        width: 45%;
    }
    
    ${({ theme }) => theme.mq.widescreen} {
        width: 40%;
    }    
`

export const ButtonLink = styled(AniLink)`
    border: 2px solid ${({theme}) => theme.color.blue};
    background-color: ${({theme}) => theme.color.blue};
    color: ${({theme}) => theme.color.white};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family.avenir};
    font-weight: bold;
    letter-spacing: 1.6px;
    padding: 13px 22px;
    text-decoration: none;
    font-size: 1.3rem;

    ${({ theme }) => theme.mq.desktop} {
        padding: 18px 25px;
    }

    ${({ theme }) => theme.mq.widescreen} {
        padding: 18px 35px;
    }
`