import styled from "styled-components"

import { Section } from "../../atoms/Section/Section"
import { Wrapper } from "../../atoms/Wrapper/Wrapper"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link"

export const ScrollButton = styled.button`
    display: none;

    ${({ theme }) => theme.mq.desktop} {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 12px;
        position: absolute;
        top: -100px;
        right: 0;
        width: 25%;
        height: 100px;
        z-index: 100;
        border: 0;
        background-color: #000;
        color: #fff;
        font-family: ${({ theme }) => theme.font.family.avenir};
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 1.8px;
    }

    ${({ theme }) => theme.mq.widescreen} {
        padding: 48px;
        justify-content: initial;
    }
`

export const StyledIcon = styled.img`
    margin-right: 24px;
`

export const StyledSection = styled(Section)`
    ${({ theme }) => theme.mq.desktop} {
        position: relative; 

        &::after {
            content: '';
            position: absolute;
            top: -100px;
            right: 0;
            width: 25%;
            height: 700px;
            background-color: black;
        }
    }
`

export const StyledWrapper = styled(Wrapper)`
    ${({ theme }) => theme.mq.desktop} {
        display: flex;
        justify-content: space-between;
    }
`

export const InfoContainer = styled.div`
    ${({theme }) => theme.mq.desktop} {
        width: 50%;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 45%;
    }
`

export const StyledImg = styled(Img)`
    ${({ theme }) => theme.mq.desktop} {
        width: 45%;
        z-index: 10;
        max-height: 800px;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 40%;
    }

    ${({ theme }) => theme.mq.fullhd} {
        max-height: initial;
    }
`

export const ButtonLink = styled(AniLink)`
    border: 2px solid ${({theme}) => theme.color.blue};
    background-color: ${({theme}) => theme.color.blue};
    color: ${({theme}) => theme.color.white};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family.avenir};
    font-weight: bold;
    letter-spacing: 1.6px;
    padding: 13px 22px;
    text-decoration: none;
    font-size: 1.3rem;

    ${({ theme }) => theme.mq.desktop} {
        padding: 18px 25px;
    }

    ${({ theme }) => theme.mq.widescreen} {
        padding: 18px 35px;
    }
`

export const StyledDownloadLink = styled.a`
    display: flex;
    text-decoration: none;
    align-items: center;
    color: ${({ theme }) => theme.color.blue};
    font-weight: bold;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.6px;
    font-size: 1.33rem;
    padding: 13px 22px;
    border: 2px solid ${({ theme }) => theme.color.blue};
    margin-top: 1.6rem;    
    
    ${({ theme }) => theme.mq.desktop} {
        margin-top: 0;
        margin-left: 3.2rem;
        padding: 18px 22px;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    ${({ theme }) => theme.mq.desktop} {
        flex-direction: row;
    }
`
