import styled, { css } from "styled-components"
import Img from 'gatsby-image'


const handleItemNumber = number => {
    switch (number) {
      case 0:
        return css`grid-column: 1/ span 2; grid-row: 1;`;
      case 1:
        return css``;
      case 2:
        return css`grid-column: 4; grid-row: 1/ span 3;`;
      case 3:
        return css`grid-column: 5/ span 7; grid-row: 1;`;
      case 4:
        return css`grid-column: 1/ span 3; grid-row: 2;`;
      case 5:
        return css`grid-column: 5; grid-row: 2;`;
      case 6:
        return css`grid-column: 6/ span 7;`;
      default:
        return css`grid-column: 1/ span 2; grid-row: 1;`;
    }
  };
  
  export const Grid = styled.div`
      display: grid;
      width: 100%;
      
      ${({ theme }) => theme.mq.desktop} {
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 250px 250px;
      }
  `
  
  export const ImgWrapper = styled.div`
    margin: ${(props) => props.margin};
    position: relative;
    width: 100%;
    height: 100%;
  `
  
  export const StyledImg = styled(Img)`
      position: absolute!important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover!important;
  `
  
  export const GalleryItem = styled.div`
    overflow: hidden;
    cursor: pointer;
  
    ${({ item }) => handleItemNumber(item)}
  `