import React from "react"
import styled from "styled-components"
import InvestmentsBoxItem from "../../atoms/InvestmentsBoxItem/InvestmentsBoxItem"

import gondolaIcon from "../../../images/public-transport.svg"
import roadIcon from "../../../images/road.svg"
import trainIcon from "../../../images/train.svg"
import yachtIcon from "../../../images/boat.svg"

const StyledWrapper = styled.div`
    margin-top: 64px;
    text-align: center;

    ${({ theme }) => theme.mq.desktop} {
        display: flex;
        flex-flow: row wrap;
    }
`

const StyledInvestmentsBoxItem = styled(InvestmentsBoxItem)`
    margin-bottom: 3.2rem;

    ${({ theme }) => theme.mq.desktop} {
        width: 50%;
    }
`

const InvestmentsBox = ({ className }) => (
   <StyledWrapper className={className}>
        <StyledInvestmentsBoxItem icon={gondolaIcon} text="Kolej gondolowa w Solinie"/>
        <StyledInvestmentsBoxItem icon={roadIcon} text="Rozwój infrastruktury drogowej"/>
        <StyledInvestmentsBoxItem icon={trainIcon} text="Rozwój infrastruktury kolejowej"/>
        <StyledInvestmentsBoxItem icon={yachtIcon} text="Port jachtowy Marina"/>
   </StyledWrapper>
)

export default InvestmentsBox